'use client';

import React, { ReactNode, useEffect } from 'react';

import AgreeNewTerms from '@/components/Pages/AgreeNewTerms';
import PhoneNumber from '@/components/Pages/PhoneNumber';
import SelectSignupDrafts from '@/components/Pages/SelectSignupDrafts';
import Signin from '@/components/Pages/Signin';
import { useAuth } from '@/hooks/useAuth';
import { useInternalRouter } from '@/hooks/useInternalRouter';

function AuthedRoute({
  children,
  requireArtist,
}: {
  children?: ReactNode;
  requireArtist: boolean;
}) {
  const { me, isLoading } = useAuth();

  const router = useInternalRouter();

  useEffect(() => {
    if (me && !me.isArtist && me.isDoorPerson) {
      router.replace(`/door/calendar`);
    }
  }, [router, me]);
  if (!me && isLoading) {
    return null;
  }

  if (!me) {
    return <Signin />;
  }

  if (!me.isArtist && me.isDoorPerson) {
    return null;
  }

  if (!me.agreedToLatestTerms) {
    return <AgreeNewTerms />;
  }

  if (!me.phonenumber) {
    return <PhoneNumber />;
  }

  if (!requireArtist || me.artists.length > 0) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return <SelectSignupDrafts />;
}

export default AuthedRoute;
