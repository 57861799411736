import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '@/lib/utils';

const badgeVariants = cva(
  `inline-flex items-center rounded-60 border px-8 h-24 transition-colors focus:outline-none component-small`,
  {
    variants: {
      variant: {
        default: `border-transparent bg-gray-600 text-gray-50`,
        'default-outlined': `border-gray-300 bg-white text-gray-50`,
        red: `border-transparent bg-danger-red-50 text-danger-red-100`,
        'red-outlined': `border-danger-red-100 bg-white text-danger-red-100`,
        orange: `border-transparent bg-orange-200 text-white`,
        'orange-outlined': `border-orange-200 bg-white text-orange-200`,
      },
    },
    defaultVariants: {
      variant: `default`,
    },
  },
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant, className }))} {...props} />
  );
}

export { Badge, badgeVariants };
