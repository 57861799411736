import getSymbolFromCurrency from 'currency-symbol-map';
import { parseISO } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { formatInTimeZone } from 'date-fns-tz';
import Decimal from 'decimal.js';

import {
  AlternativeSlotFragment,
  MySlotFragment,
  SlotDetailFragment,
  SlotFragment,
} from '@/generated/graphql';

export enum SlotType {
  Recommended = `Recommended`,
  Upcoming = `Upcoming`,
  Past = `Past`,
}

export class SlotTypeUtil {
  public static fromSlot(
    slot: SlotDetailFragment | MySlotFragment | SlotFragment,
  ): SlotType {
    if (slot.isPast) {
      return SlotType.Past;
    }
    if (slot.isUpcoming) {
      return SlotType.Upcoming;
    }

    return SlotType.Recommended;
  }
}

export const getArtistName = (slot: SlotFragment) => {
  if (slot.selectedArtist) {
    return slot.selectedArtist.name;
  }

  return slot.candidate;
};

export function formatSlotModel(
  slot: MySlotFragment | SlotFragment | AlternativeSlotFragment,
  formatOption:
    | {
        type: 'start';
        formatStartDatetime: string;
      }
    | {
        type: 'end';
        formatEndDatetime: string;
      }
    | {
        type: 'both';
        formatStartDatetime: string;
        formatEndDatetime: string;
      } = {
    type: `both`,
    formatStartDatetime: `MM/dd h:mm a`,
    formatEndDatetime: `h:mm a zzz`,
  },
): string {
  switch (formatOption.type) {
    case `start`: {
      return formatInTimeZone(
        parseISO(slot.startDatetime),
        slot.show.stage.venue.region.timezone,
        formatOption.formatStartDatetime,
        {
          locale: enUS,
        },
      );
    }
    case `end`: {
      return formatInTimeZone(
        parseISO(slot.endDatetime),
        slot.show.stage.venue.region.timezone,
        formatOption.formatEndDatetime,
        {
          locale: enUS,
        },
      );
    }
    case `both`: {
      const formattedStartDateTime = formatInTimeZone(
        parseISO(slot.startDatetime),
        slot.show.stage.venue.region.timezone,
        formatOption.formatStartDatetime,
        {
          locale: enUS,
        },
      );

      const formattedEndDateTime = formatInTimeZone(
        parseISO(slot.endDatetime),
        slot.show.stage.venue.region.timezone,
        formatOption.formatEndDatetime,
        {
          locale: enUS,
        },
      );

      return `${formattedStartDateTime} - ${formattedEndDateTime}`;
    }
    default: {
      throw new Error(`Unhandled format option type: ${formatOption}`);
    }
  }
}

export const getPayoutLabel = (slot: MySlotFragment) =>
  `${getSymbolFromCurrency(slot.payoutAmount?.currency ?? `USD`)}${new Decimal(
    slot.payoutAmount?.amount ?? 0,
  ).toFixed(2)}`;
