import getSymbolFromCurrency from 'currency-symbol-map';

import { OfferFragment } from '@/generated/graphql';

type MinTicketsOffer = {
  type: 'min_tickets';
  minTickets: number;
  percentCut: number;
};
type TotalMinTicketsOffer = {
  type: 'total_min_tickets';
  minTickets: number;
  percentCut: number;
};
type MinDollarsOffer = {
  type: 'min_dollars';
  minDollarAmount: number;
  percentCut: number;
};
type TotalMinDollarsOffer = {
  type: 'total_min_dollars';
  minDollarAmount: number;
  percentCut: number;
};
type GuaranteeOffer = {
  type: 'guarantee';
  amount: number;
  currency: string;
};
type CustomOffer = {
  type: 'custom';
  value: string;
};

type Offer =
  | MinTicketsOffer
  | TotalMinTicketsOffer
  | MinDollarsOffer
  | TotalMinDollarsOffer
  | GuaranteeOffer
  | CustomOffer;

export const getOfferText = (offer: OfferFragment) => {
  // eslint-disable-next-line no-underscore-dangle
  switch (offer.__typename) {
    case `CustomOffer`:
      return offer.value;
    case `GuaranteeOffer`:
      return `${getSymbolFromCurrency(offer.currency)}${offer.amount.toFixed(
        2,
      )} guarantee`;
    case `MinTicketsOffer`:
      if (offer.percentCut === 0) {
        return `No payout`;
      }

      return `${offer.percentCut}% after your ${offer.minTickets} polled tickets`;
    case `TotalMinTicketsOffer`:
      if (offer.percentCut === 0) {
        return `No payout`;
      }

      return `${offer.percentCut}% of total tickets after ${offer.minTickets} tickets`;
    case `MinDollarsOffer`:
      if (offer.percentCut === 0) {
        return `No payout`;
      }

      return `${offer.percentCut}% of your polled tickets after $${offer.minDollarAmount}`;
    case `TotalMinDollarsOffer`:
      if (offer.percentCut === 0) {
        return `No payout`;
      }

      return `${offer.percentCut}% of total tickets after $${offer.minDollarAmount}`;
    default:
      // eslint-disable-next-line no-underscore-dangle
      throw new Error(`Unknown offer type: ${offer.__typename}`);
  }
};

export default Offer;
