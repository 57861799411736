import { UilArrowRight } from '@iconscout/react-unicons';
import React, { ReactNode } from 'react';

import AppBar from '@/components/Layout/AppBar';
import Background from '@/components/Layout/Background';
import Container from '@/components/Layout/Container';
import MobilePx8 from '@/components/Layout/MobilePx8';
import {
  ArtistFragment,
  MyArtistFragment,
  SignupDraftFragment,
} from '@/generated/graphql';
import ImageWithFallback from '@/utils/ImageWithFallback';

import MdOnly from 'src/components/Layout/MdOnly';

function ListItem(props: {
  profile: ArtistFragment | MyArtistFragment | SignupDraftFragment;
  handleSelectProfile: (uuid: string) => unknown;
}) {
  const { profile, handleSelectProfile } = props;

  return (
    <button
      className="flex h-74 w-full rounded-16 bg-white shadow-2 md:h-72 md:rounded-20"
      onClick={() => handleSelectProfile(profile.uuid)}
    >
      <div className="relative size-74 md:size-72">
        <ImageWithFallback
          src={profile.photo}
          key={profile.photo}
          layout="fill"
          className="rounded-l-16 object-cover md:rounded-l-20"
        />
      </div>

      <div className="flex grow justify-between max-md:py-27 max-md:pl-24 max-md:pr-25 md:gap-4 md:py-18 md:pl-24 md:pr-25">
        <div className="header-5-m flex grow items-center">
          {profile.name || `New Profile`}
        </div>
        <MdOnly>
          <div className="flex-center size-36 rounded-full bg-gray-500">
            <UilArrowRight className="size-24 fill-gray-25" />
          </div>
        </MdOnly>
      </div>
    </button>
  );
}

function Wrapper(props: { children?: ReactNode }) {
  const { children } = props;

  return (
    <Background backgroundColor="#FFFFFF">
      <AppBar
        start={<AppBar.MenuButton />}
        end={<AppBar.LogoutButton />}
        backgroundColor="#FFFFFF"
      />
      <div className="h-40 md:h-78" />

      <Container className="md:max-w-675">
        <MobilePx8>{children}</MobilePx8>
      </Container>
    </Background>
  );
}

function SelectProfiles(props: {
  children?: ReactNode;
  title: ReactNode;
  profiles: (MyArtistFragment | SignupDraftFragment)[];
  handleSelectProfile: (uuid: string) => unknown;
}) {
  const { profiles, title, handleSelectProfile, children } = props;

  return (
    <Wrapper>
      <div className="flex flex-col">
        <div className="header-1-b md:header-0-b text-gray-10">{title}</div>
        <div className="h-40 md:h-56" />

        <div className="flex flex-col gap-10 md:gap-8">
          {profiles.map((profile) => (
            <ListItem
              profile={profile}
              key={profile.uuid}
              handleSelectProfile={handleSelectProfile}
            />
          ))}
          {children}
        </div>
      </div>
    </Wrapper>
  );
}

export default SelectProfiles;
