import useSWR from 'swr';

import {
  SignupDraftsDocument,
  SignupDraftsQuery,
  SignupDraftsQueryVariables,
  SignupDraftState,
} from '@/generated/graphql';
import client from '@/graphql/apollo';
import { useAuth } from '@/hooks/useAuth';

function useSignupDrafts() {
  const { me } = useAuth();

  return useSWR([`/signup_drafts`, me?.uuid], async () => {
    if (!me) {
      return null;
    }

    const response = await client.query<
      SignupDraftsQuery,
      SignupDraftsQueryVariables
    >({
      query: SignupDraftsDocument,
    });

    if (!response.data.me) {
      throw new Error(`User is not logged in`);
    }

    return response.data.me.signupDrafts.filter(
      (it) => it.state !== SignupDraftState.Approved,
    );
  });
}

export default useSignupDrafts;
