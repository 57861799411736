import { parseISO } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { formatInTimeZone } from 'date-fns-tz';

import {
  MySlotFragment,
  SlotDetailFragment,
  SlotFragment,
} from '@/generated/graphql';
import { SlotType, SlotTypeUtil } from '@/models/Slot';

export class SlotUtils {
  public static getSlotImage(slot: MySlotFragment | SlotFragment): string[] {
    switch (SlotTypeUtil.fromSlot(slot)) {
      case SlotType.Upcoming:
      case SlotType.Past: {
        if (slot.show.bannerbearIgPoster) {
          return [
            ...(slot.show.stage.photos?.fileCdnUrls ?? []),
            ...(slot.show.stage.venue.photos?.fileCdnUrls ?? []),
            slot.show.bannerbearIgPoster.imageUrl,
          ];
        }
        break;
      }
      case SlotType.Recommended:
      default: {
        break;
      }
    }

    return [
      ...(slot.show.stage.photos?.fileCdnUrls ?? []),
      ...(slot.show.stage.venue.photos?.fileCdnUrls ?? []),
    ];
  }
}

export function formatSlotDetailModel(
  slot: SlotFragment,
  formatOption:
    | {
        type: 'load-in';
        formatStr: string;
      }
    | {
        type: 'soundcheck';
        formatStr: string;
      },
): string | null {
  switch (formatOption.type) {
    case `load-in`: {
      if (!slot.loadinDatetime) {
        return null;
      }

      return formatInTimeZone(
        parseISO(slot.loadinDatetime),
        slot.show.stage.venue.region.timezone,
        formatOption.formatStr,
        {
          locale: enUS,
        },
      );
    }
    case `soundcheck`: {
      if (!slot.soundcheckDatetime) {
        return null;
      }

      return formatInTimeZone(
        parseISO(slot.soundcheckDatetime),
        slot.show.stage.venue.region.timezone,
        formatOption.formatStr,
        {
          locale: enUS,
        },
      );
    }
    default: {
      throw new Error(`Unknown format option type: ${formatOption}`);
    }
  }
}

export const getTicketSalesCount = (
  slot: SlotDetailFragment & MySlotFragment,
): number | null => {
  switch (SlotTypeUtil.fromSlot(slot)) {
    case SlotType.Past: {
      switch (slot.offer.type) {
        case `custom`:
        case `min_tickets`:
        case `min_dollars`: {
          return slot.paidTicketCount ?? 0;
        }
        case `guarantee`: {
          return null;
        }
        case `total_min_dollars`:
        case `total_min_tickets`: {
          return slot.show.totalPaidTicketCount ?? null;
        }
        default: {
          throw new Error(`Unknown offer type: ${slot.offer.type}`);
        }
      }
    }
    case SlotType.Recommended: {
      return null;
    }
    case SlotType.Upcoming: {
      return slot.show.totalPaidTicketCount ?? null;
    }
    default: {
      throw new Error(`Unknown slot type: ${slot}`);
    }
  }
};
