'use client';

import { UilArrowRight, UilPlusCircle } from '@iconscout/react-unicons';
import { captureException } from '@sentry/core';
import React, { useCallback, useEffect } from 'react';
import toast from 'react-hot-toast';

import SelectProfiles from '@/components/Pages/SelectProfiles';
import { useAuth } from '@/hooks/useAuth';
import useCreateSignupDraft from '@/hooks/useCreateSignupDraftMutation';
import useFetching from '@/hooks/useFetching';
import { useInternalRouter } from '@/hooks/useInternalRouter';
import useSignupDrafts from '@/hooks/useSignupDrafts';
import { useLoading } from '@/utils/Loading/LoadingProvider';

import MdOnly from 'src/components/Layout/MdOnly';

function SelectSignupDrafts() {
  const { me } = useAuth();
  const { data: signupDrafts, error, isLoading, mutate } = useSignupDrafts();
  const refetch = useCallback(() => mutate(), [mutate]);

  useFetching({
    data: signupDrafts,
    error,
    isLoading,
  });
  const router = useInternalRouter();

  useEffect(() => {
    if (me && !me.ableToCreateNewSignupDraft && signupDrafts?.length === 1) {
      router.push({
        type: `signup-draft`,
        signupDraftUuid: signupDrafts[0].uuid,
      });
    }
  }, [me, router, signupDrafts]);

  const handleSelectProfile = useCallback(
    (uuid: string) => {
      const signupDraft = signupDrafts?.find((draft) => draft.uuid === uuid);

      if (signupDraft) {
        router.push({
          type: `signup-draft`,
          signupDraftUuid: signupDraft.uuid,
        });
      }
    },
    [router, signupDrafts],
  );

  const createSignupDraft = useCreateSignupDraft();

  const { loader } = useLoading();
  const handleAddNewProfile = useCallback(async () => {
    loader.on();
    try {
      const uuid = await createSignupDraft();

      await refetch();
      router.push({
        type: `signup-draft`,
        signupDraftUuid: uuid,
      });
    } catch (e) {
      toast.error(`${e}`);
      captureException(e);
    }
    loader.off();
  }, [loader, createSignupDraft, refetch, router]);

  if (!signupDrafts) {
    return null;
  }

  return (
    <SelectProfiles
      title="Select a profile to continue"
      handleSelectProfile={handleSelectProfile}
      profiles={signupDrafts}
    >
      {me?.ableToCreateNewSignupDraft && (
        <button
          className="flex h-74 w-full rounded-16 bg-white shadow-2 md:h-72 md:rounded-20"
          onClick={handleAddNewProfile}
        >
          <div className="flex-center relative size-74 md:size-72">
            <div className="flex-center size-full rounded-l-16 rounded-r-8 bg-gray-400 md:rounded-l-20">
              <UilPlusCircle className="size-20 fill-gray-25" />
            </div>
          </div>
          <div className="flex grow justify-between max-md:py-27 max-md:pl-24 max-md:pr-25 md:gap-4 md:py-18 md:pl-24 md:pr-25">
            <div className="header-5-m flex grow items-center">
              Create a new profile
            </div>
            <MdOnly>
              <div className="flex-center size-36 rounded-full bg-gray-500">
                <UilArrowRight className="size-24 fill-gray-25" />
              </div>
            </MdOnly>
          </div>
        </button>
      )}
    </SelectProfiles>
  );
}

export default SelectSignupDrafts;
