import { v4 as uuidv4 } from 'uuid';

import constants from '@/constants/constants';
import InvalidState from '@/errors/InvalidState';
import { useCreateSignupDraftMutation } from '@/generated/graphql';

function useCreateSignupDraft() {
  const [createSignupDraft] = useCreateSignupDraftMutation();

  return async () => {
    const uuid = await createSignupDraft()
      .then((it) => it.data?.createSignupDraft.uuid)
      .catch((e) => {
        if (e.message === constants.DEMO_USER_ERROR_MESSAGE) {
          return uuidv4();
        }
        throw e;
      });

    if (!uuid) {
      throw new InvalidState();
    }

    return uuid;
  };
}

export default useCreateSignupDraft;
