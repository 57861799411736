import { differenceInHours } from 'date-fns';
import { useMediaQuery } from 'react-responsive';

import { Badge } from '@/components/ui/badge';

function ExpiringInBadge(props: { expiresAt: Date }) {
  const { expiresAt } = props;
  const expiresInHours = differenceInHours(expiresAt, new Date());
  const isMd = useMediaQuery({
    query: `(min-width: 768px)`,
  });

  return <Badge variant="red">Expiring in {expiresInHours} hrs</Badge>;
}

export default ExpiringInBadge;
